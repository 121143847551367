import PropTypes from 'prop-types'
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo/seo'
import HeroImg from '../assets/images/hero-careers.png'

const query = graphql`
    query {
        contentfulCareersPage {
            careersTitle
            careersItems {
                title
                description
            }
        }
    }
`

const Content = ({ location }) => {
    const data = useStaticQuery(query)

    return (
        <Layout>
            <Seo
                title="Careers - South Lane"
                desc="You don't need to have a degree. You don't need to have 10+ years of experience. We're looking for people who know what they are doing and are humble about it."
                pathname={location.pathname}
            />
            <div className="content-narrow content-narrow--small">
                <h1>{data.contentfulCareersPage.careersTitle}</h1>
            </div>

            <div className="content-hero-image">
                <img src={HeroImg} alt="Careers" />
            </div>

            <div className="content-narrow">
                {data.contentfulCareersPage.careersItems.map(item => (
                    <div key={item.title} className="common-content-block">
                        {item.title && <h6>{item.title}</h6>}
                        <div
                            // eslint-disable-next-line
                            dangerouslySetInnerHTML={{
                                __html: item.description,
                            }}
                        />
                    </div>
                ))}
            </div>
        </Layout>
    )
}

Content.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
        .isRequired,
}

export default Content
